'use client';

import { PropsWithChildren } from 'react';
import clsx from 'clsx';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

import SendLogOnClickWrapper from '@/components/SendLogOnClickWrapper';
import CvsLogo from '@/components/SVG/Logo/CvsLogo';
import WalgreensLogo from '@/components/SVG/Logo/WalgreensLogo';
import WalmartLogo from '@/components/SVG/Logo/WalmartLogo';
import { LANDING_PAGES_PATH } from '@/constants';
import { Partners } from '@/types/partner';
import getBasePath from '@/utils/getBasePath';

import styles from './styles.module.scss';

export default function PartnerLogoWrapper({ children }: PropsWithChildren) {
  const pathname = usePathname();
  const isHomePage = pathname === getBasePath();
  const shouldDisplaySpacer =
    process.env.NEXT_PUBLIC_PARTNER === Partners.WALMART ||
    process.env.NEXT_PUBLIC_PARTNER === Partners.CVS ||
    process.env.NEXT_PUBLIC_PARTNER === Partners.WALGREENS;

  return (
    <SendLogOnClickWrapper event="ui_header_logo_clicked">
      <Link
        prefetch={!isHomePage}
        href={LANDING_PAGES_PATH.includes(pathname) ? pathname : '/'}
        className={clsx(
          styles.logo,
          styles[process.env.NEXT_PUBLIC_PARTNER || ''],
        )}
        aria-label={`${process.env.NEXT_PUBLIC_PARTNER} homepage`}
      >
        {children}
        {shouldDisplaySpacer && <div className={styles.spacer} />}
        {process.env.NEXT_PUBLIC_PARTNER === Partners.WALMART && (
          <WalmartLogo />
        )}
        {process.env.NEXT_PUBLIC_PARTNER === Partners.CVS && <CvsLogo />}
        {process.env.NEXT_PUBLIC_PARTNER === Partners.WALGREENS && (
          <WalgreensLogo />
        )}
      </Link>
    </SendLogOnClickWrapper>
  );
}
